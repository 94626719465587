export const camelCaseToUserText = (input: string): string => (
  `${input[0].toUpperCase()}${input.replace(/([A-Z](?=[a-z]+)|[A-Z]+(?![a-z]))/g, ' $1').slice(1)}`
);

export const splitByCapitalLetter = (input: string) => input.match(/[A-Z][a-z]+/g)?.join(' ');

const convertToObject = (obj: any): any => {
  if (!!obj && Array.isArray(obj)) {
    const convertedArray = [];
    for (const item of obj) {
      convertedArray.push(convertToObject(item));
    }
  } else if (!!obj && typeof obj === 'object') {
    const plainObject: any = {};

    Object.getOwnPropertyNames(obj).forEach((key) => {
        plainObject[key] = convertToObject(obj[key]);
    });

    return plainObject;
  }
  return obj;
}
export const stringifyError = (error: any): string => {
  const convertedObject = convertToObject(error);
  if (typeof error === 'object' && error !== null) {
    return JSON.stringify(convertedObject);
  }
  return String(error); // For cases when error is not an object
}